<template>
  <div>
    <h2 class="slm-bubble-carousel__heading">{{heading}}</h2>
    <swiper
      class="slm-bubble-carousel__swiper slm-carousel"
      :initial-slide="(posts.length - 1) % 2 === 0 ? (posts.length - 1) / 2 : posts.length / 2"
      :modules="swiperModules"
      :slides-per-view="'auto'"
      :centeredSlides="true"
      :space-between="0"
      :navigation="navigationOptions"
      :loop="loop"
      :pagination="paginationOptions"
      :slideToClickedSlide="true"
      @transition-end="handleDuplicates"
    >
      <swiper-slide class="slm-carousel__slide slm-bubble-carousel__slide" v-for="post in posts" :key="post.url">
          <post-component :post="post"/>
      </swiper-slide>
      <button class="slm-carousel__nav-button slm-bubble-carousel__swiper--nav-button prev">
        <span class="show-for-sr">Visa föregående slide</span>
      </button>
      <div class="slm-carousel__pagination slm-bubble-carousel__swiper--pagination"></div>
      <button class="slm-carousel__nav-button slm-bubble-carousel__swiper--nav-button next">
        <span class="show-for-sr">Visa nästa slide</span>
      </button>
    </Swiper>
  </div>
  
</template>

<script>
import PostComponent from './components/post-component.vue';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

export default {
  name: 'page-carousel',
  data() {
    return {
      swiperModules: [Navigation, Pagination],
      navigationOptions: {
        enabled: true,
        nextEl: '.slm-bubble-carousel__swiper--nav-button.next',
        prevEl: '.slm-bubble-carousel__swiper--nav-button.prev'
      },
      paginationOptions: {
        el: '.slm-bubble-carousel__swiper--pagination',
        type: 'bullets',
        clickable: true,
        bulletClass: 'slm-carousel__pagination--bullet',
        bulletActiveClass: 'active',
        currentClass: 'current',
        renderBullet: (index, className) => {
          return `<span role="button" class="${className}" aria-label="Gå till slide ${index + 1}"></span>`
        }
      }
    }
  },
  computed: {
    posts() {
      return this.$store.state.posts;
    },
    heading() {
      return this.$store.state.heading;
    },
    loop() {
      return this.$store.state.loop;
    }
  },
  methos: {
    handleDuplicates(swiper) {
      if(this.loop) {
        swiper.loopDestroy();
        swiper.loopCreate();
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    PostComponent
  }
}
</script>
