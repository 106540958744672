import { createApp, h } from 'vue'
import App from './App.vue'
import store from './store'

const setupApp = (element, data) => {
    createApp({
        created() {
            this.$store.dispatch('initApp', data);
        },
        render: () => h(App)
    }).use(store).mount(element);
}

if(process.env.NODE_ENV === 'production') {
    window.BUBBLE_CAROUSEL = window.BUBBLE_CAROUSEL || setupApp
} else {
    const appElement = document.querySelector('#app');
    if(appElement) {
        const mockData = require('./mock-state').default;
        setupApp(appElement, mockData);
    }
}
